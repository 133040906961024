import Vue from 'vue';
import GmapVue from 'gmap-vue';
import './assets/styles/vue2datepicker.css';
import VueDatePicker from 'vue2-datepicker';
import env from './env';
import store from './store';
import router from './router';
import i18n from './i18n';
import apolloProvider from './apollo';
import permissions from './permissions';
import modalsController from './modalscontroller';
import helper from './helper';
import './filters';
import './graphql';
import './background-sync';

import App from './App.vue';
import './registerServiceWorker';
import './registerGlobalComponents';

// Import TailwindCSS
import './assets/styles/tailwind.css';

// Import addons
Vue.use(VueDatePicker, {
  lang: 'nl'
});

const AddToCalendar = require('vue-add-to-calendar');

Vue.use(AddToCalendar);

Vue.use(GmapVue, {
  load: {
    key: 'AIzaSyCo7VSXkqQVw-z8WdWdTKA_RfeXH8KjeX8',
    libraries: 'places'
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false
});

Vue.config.productionTip = false;

Vue.directive('scroll', {
  inserted: (el, binding) => {
    const f = evt => {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    };
    window.addEventListener('scroll', f);
  }
});

window.rootVue = new Vue({
  env,
  router,
  store,
  i18n,
  apolloProvider,
  permissions,
  modalsController,
  helper,
  render: h => h(App)
}).$mount('#app');
